import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import { Container, Row, Column } from '../Grid'
import { Navigation } from '../Navigation'
import { breakPoints } from '../Layout/theme'

const Wrapper = styled(BackgroundImage)`
  height: 300px;

  @media (max-width: ${breakPoints.xs}) {
    height: 240px;
  }
`

const PageHeader = ({ children, background, hideNavigation }) => {
  return (
    <Wrapper Tag="header" fluid={background} critical fadeIn={false}>
      <Container
        style={
          hideNavigation && {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }
        }>
        {!hideNavigation && (
          <Row>
            <Navigation />
          </Row>
        )}
        <Row
          style={
            hideNavigation && {
              width: '100%',
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }
          }>
          <Column
            display={{ md: 6 }}
            offset={{ md: 3 }}
            style={{ paddingTop: hideNavigation ? 0 : 30 }}>
            {children}
          </Column>
        </Row>
      </Container>
    </Wrapper>
  )
}

export { PageHeader }
